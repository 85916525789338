import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getToken, getUserData } from '@/auth/utils'
import axios from 'axios'
import moment from 'moment'
import VueCookies from 'vue-cookies'

const userToken = getToken()
export default function useInvoicesList() {
  const startDateRaw = moment().subtract(1, 'months').format('YYYY-MM-DD')
  const endDateRaw = moment().format('YYYY-MM-DD')
  const toast = useToast()
  const refInvoicesListTableConfirmed = ref(null)
  const refInvoicesListTableUnconfirmed = ref(null)
  const refInvoicesListTableEmpty = ref(null)
  const tableColumns = [
    { key: 'No', sortable: true, thStyle: { width: '5%' } },
    {
        label: 'Code', key: 'code', sortable: true, thStyle: { width: '7%' },
    },
    {
        label: 'Agreement Ref', key: 'agreement_ref', sortable: true, thStyle: { width: '5%' },
    },
    {
      label: 'Customer', key: 'customer', sortable: true, thStyle: { width: '5%' },
    },
    {
      label: 'Description', key: 'description', sortable: true, thStyle: { width: '5%' },
    },
    {
      label: 'Issued Date', key: 'issued_date', sortable: true, thStyle: { width: '5%' },
    },
    {
      label: 'Start Date', key: 'start_date', sortable: true, thStyle: { width: '7%' },
    },
    {
      label: 'End Date', key: 'end_date', sortable: true, thStyle: { width: '7%' },
    },
    {
      label: 'Issued By', key: 'issued_by', sortable: true, thStyle: { width: '7%' },
    },
    { key: 'Actions', sortable: true },
  ]
  const perPage = ref(10)
  const perPageConfirmed = ref(10)
  const perPageUnconfirmed = ref(10)
  const perPageEmpty = ref(10)
  const branchId = ref('')
  const totalInvoices = ref(0)
  const totalInvoicesConfirmed = ref(0)
  const totalInvoicesUnconfirmed = ref(0)
  const totalInvoicesEmpty = ref(0)
  const currentPage = ref(1)
  const currentPageConfirmed = ref(1)
  const currentPageUnconfirmed = ref(1)
  const currentPageEmpty = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const searchQueryConfirmed = ref('')
  const searchQueryUnconfirmed = ref('')
  const searchQueryEmpty = ref('')
  const startDate = ref(startDateRaw)
  const endDate = ref(endDateRaw)
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const startDateIn = moment(startDate.value).format('YYYY-MM-DD')
    const endDateIn = moment(endDate.value).format('YYYY-MM-DD')
    const searchQueryVal = searchQuery.value ? searchQuery.value : ''

    const localItemsCountConfirmed = refInvoicesListTableConfirmed.value ? refInvoicesListTableConfirmed.value.localItems.length : 0
    const searchQueryValConfirmed = searchQueryConfirmed.value ? searchQueryConfirmed.value : ''

    const localItemsCountUnconfirmed = refInvoicesListTableUnconfirmed.value ? refInvoicesListTableUnconfirmed.value.localItems.length : 0
    const searchQueryValUnconfirmed = searchQueryUnconfirmed.value ? searchQueryUnconfirmed.value : ''

    const localItemsCountEmpty = refInvoicesListTableEmpty.value ? refInvoicesListTableEmpty.value.localItems.length : 0
    const searchQueryValEmpty = searchQueryEmpty.value ? searchQueryEmpty.value : ''
    return {
      of: totalInvoices.value,
      searchQuery: searchQueryVal,
      fromConfirmed: perPageConfirmed.value * (currentPageConfirmed.value - 1) + (localItemsCountConfirmed ? 1 : 0),
      toConfirmed: perPageConfirmed.value * (currentPageConfirmed.value - 1) + localItemsCountConfirmed,
      ofConfirmed: totalInvoicesConfirmed.value,
      searchQueryonfirmed: searchQueryValConfirmed,
      fromUnconfirmed: perPageUnconfirmed.value * (currentPageUnconfirmed.value - 1) + (localItemsCountUnconfirmed ? 1 : 0),
      toUnconfirmed: perPageUnconfirmed.value * (currentPageUnconfirmed.value - 1) + localItemsCountUnconfirmed,
      ofUnconfirmed: totalInvoicesUnconfirmed.value,
      searchQueryUnconfirmed: searchQueryValUnconfirmed,
      fromEmpty: perPageEmpty.value * (currentPageEmpty.value - 1) + (localItemsCountEmpty ? 1 : 0),
      toEmpty: perPageEmpty.value * (currentPageEmpty.value - 1) + localItemsCountEmpty,
      ofEmpty: totalInvoicesEmpty.value,
      searchQueryEmpty: searchQueryValEmpty,
      branchId: branchId.value,
      DateFrom: startDateIn,
      DateTo: endDateIn,
    }
  })

  const refetchDataAll = () => {
    refInvoicesListTableConfirmed.value.refresh()
    refInvoicesListTableUnconfirmed.value.refresh()
    refInvoicesListTableEmpty.value.refresh()
  }
  const refetchDataAllInvoice = () => {
  }
  const refetchDataConfirmedInvoice = () => {
    refInvoicesListTableConfirmed.value.refresh()
  }
  const refetchDataUnconfirmedInvoice = () => {
    refInvoicesListTableUnconfirmed.value.refresh()
  }
  const refetchDataEmptyInvoice = () => {
    refInvoicesListTableEmpty.value.refresh()
  }

  watch([startDate, endDate, branchId], () => {
    refetchDataAll()
  })
  watch([currentPage, perPage, searchQuery], () => {
    refetchDataAllInvoice()
  })
  watch([currentPageConfirmed, perPageConfirmed, searchQueryConfirmed], () => {
    refetchDataConfirmedInvoice()
  })
  watch([currentPageUnconfirmed, perPageUnconfirmed, searchQueryUnconfirmed], () => {
    refetchDataUnconfirmedInvoice()
  })
  watch([currentPageEmpty, perPageEmpty, searchQueryEmpty], () => {
    refetchDataEmptyInvoice()
  })

  const fetchInvoices = async (ctx, callback) => {
    const userKodeUnitKerja = getUserData().KodeUnitKerja
    const userRole = VueCookies.get('UserRole')
    const body = {
          DateFrom: dataMeta.value.DateFrom,
          DateTo: dataMeta.value.DateTo,
      }
      console.log(dataMeta.value.DateFrom, dataMeta.value.DateTo)
      console.log(startDateRaw)
      if (dataMeta.value.searchQuery !== '' && dataMeta.value.searchQuery !== null) {
        body.Filter = dataMeta.value.searchQuery
      }
      console.log('body')
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    axios
      .post('https://api.geolims.com/invoice_service/getcorporate', body, { headers })
      .then(response => {
        const tmp = []
        response.data.Payload.map(o => {
          const unitkerja = o.JobNo.toString().substring(0, 3)
          if (userRole === 'branch') {
            if (userKodeUnitKerja.toString() === unitkerja.toString()) {
              tmp.push(o)
            }
          } else if (dataMeta.value.branchId !== '' && dataMeta.value.branchId !== null) {
            if (dataMeta.value.branchId.toString() === unitkerja.toString()) {
              tmp.push(o)
            }
          } else tmp.push(o)
          return o
        })
        totalInvoices.value = tmp.length
        const sliced = tmp.slice(
        (currentPage.value - 1) * perPage.value,
        (currentPage.value) * perPage.value,
      )
      callback(sliced)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching invoice list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchInvoicesConfirmed = async (ctx, callback) => {
    const userKodeUnitKerja = getUserData().KodeUnitKerja
    const userRole = VueCookies.get('UserRole')
    const body = {
          DateFrom: dataMeta.value.DateFrom,
          DateTo: dataMeta.value.DateTo,
      }
      console.log(dataMeta.value.searchQueryConfirmed)
      if (dataMeta.value.searchQueryConfirmed !== '' && dataMeta.value.searchQueryConfirmed !== null) {
        body.Filter = dataMeta.value.searchQueryConfirmed
      }
      console.log('body')
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    axios
      .post('https://api.geolims.com/invoice_service/getcorporate', body, { headers })
      .then(response => {
        const tmp = []
        response.data.Payload.map(o => {
          const unitkerja = o.JobNo.toString().substring(0, 3)
          if (dataMeta.value.branchId !== '' && dataMeta.value.branchId !== null) {
            if (dataMeta.value.branchId.toString() === unitkerja.toString() && parseInt(o.invoiceApproved, 3) === 1) {
              tmp.push(o)
            }
          } else if (userRole === 'branch') {
            if (userKodeUnitKerja.toString() === unitkerja.toString() && parseInt(o.invoiceApproved, 8) === 1) {
              tmp.push(o)
            }
          } else {
            console.log('')
            if (parseInt(o.invoiceApproved, 8) === 1) {
              tmp.push(o)
            }
          }
          return o
        })
        totalInvoicesConfirmed.value = tmp.length
      const sliced = tmp.slice(
      (currentPageConfirmed.value - 1) * perPageConfirmed.value,
      (currentPageConfirmed.value) * perPageConfirmed.value,
    )
    console.log('Confirmed', tmp.length)
    console.log(sliced)
        callback(sliced)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching invoice list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchInvoicesUnconfirmed = async (ctx, callback) => {
    const userKodeUnitKerja = getUserData().KodeUnitKerja
    const userRole = VueCookies.get('UserRole')
    const body = {
          DateFrom: dataMeta.value.DateFrom,
          DateTo: dataMeta.value.DateTo,
      }
      console.log(dataMeta.value.searchQueryUnconfirmed)
      if (dataMeta.value.searchQueryUnconfirmed !== '' && dataMeta.value.searchQueryUnconfirmed !== null) {
        body.Filter = dataMeta.value.searchQueryUnconfirmed
      }
      console.log('body')
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    axios
      .post('https://api.geolims.com/invoice_service/getcorporate', body, { headers })
      .then(response => {
        const tmp = []
        response.data.Payload.map(o => {
          const unitkerja = o.JobNo.toString().substring(0, 3)
          if (dataMeta.value.branchId !== '' && dataMeta.value.branchId !== null) {
            if (dataMeta.value.branchId.toString() === unitkerja.toString() && parseInt(o.invoiceApproved, 8) === 0 && (o.Pusat_NoInvoice !== null || o.Pusat_NoInvoice !== null)) {
              tmp.push(o)
            }
          } else if (userRole === 'branch') {
            if (userKodeUnitKerja.toString() === unitkerja.toString() && parseInt(o.invoiceApproved, 8) === 0 && (o.Pusat_NoInvoice !== null || o.Pusat_NoInvoice !== null)) {
              tmp.push(o)
            }
          } else {
            console.log('')
            if (parseInt(o.invoiceApproved, 8) === 0 && (o.Pusat_NoInvoice !== null || o.Pusat_NoInvoice !== null)) {
              tmp.push(o)
            }
          }
          return o
        })
        totalInvoicesUnconfirmed.value = tmp.length
      const sliced = tmp.slice(
      (currentPageUnconfirmed.value - 1) * perPageUnconfirmed.value,
      (currentPageUnconfirmed.value) * perPageUnconfirmed.value,
    )
    console.log('perPage Unconfirmed', perPageUnconfirmed.value)
    console.log('Unconfirmed')
    console.log('kampret')
    console.log(tmp.length)
    console.log(sliced.length)
    console.log(sliced)
        callback(sliced)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching invoice list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchInvoicesEmpty = async (ctx, callback) => {
    const userKodeUnitKerja = getUserData().KodeUnitKerja
    const userRole = VueCookies.get('UserRole')
    const body = {
          DateFrom: dataMeta.value.DateFrom,
          DateTo: dataMeta.value.DateTo,
      }
      console.log(dataMeta.value.searchQueryEmpty)
      if (dataMeta.value.searchQueryEmpty !== '' && dataMeta.value.searchQueryEmpty !== null) {
        body.Filter = dataMeta.value.searchQueryEmpty
      }
      console.log('body')
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    axios
      .post('https://api.geolims.com/invoice_service/getcorporate', body, { headers })
      .then(response => {
        const tmp = []
        response.data.Payload.map(o => {
          const unitkerja = o.JobNo.toString().substring(0, 3)
          if (dataMeta.value.branchId !== '' && dataMeta.value.branchId !== null) {
            if (dataMeta.value.branchId.toString() === unitkerja.toString() && (o.Pusat_NoInvoice === null || o.Pusat_InvoiceDate === null)) {
              tmp.push(o)
            }
          } else if (userRole === 'branch') {
            if (userKodeUnitKerja.toString() === unitkerja.toString() && (o.Pusat_NoInvoice === null || o.Pusat_InvoiceDate === null)) {
              tmp.push(o)
            }
          } else {
            console.log('')
            if (o.Pusat_NoInvoice === null || o.Pusat_InvoiceDate === null) {
              tmp.push(o)
            }
          }
          return o
        })
        totalInvoicesEmpty.value = tmp.length
      const sliced = tmp.slice(
      (currentPageEmpty.value - 1) * perPageEmpty.value,
      (currentPageEmpty.value) * perPageEmpty.value,
    )
    console.log('perPage Empty', perPageEmpty.value)
    console.log('Empty')
    console.log('kampret')
    console.log(tmp.length)
    console.log(sliced.length)
    console.log(sliced)
        callback(sliced)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching invoice list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchInvoices,
    fetchInvoicesConfirmed,
    fetchInvoicesUnconfirmed,
    fetchInvoicesEmpty,
    branchId,
    tableColumns,
    perPage,
    perPageConfirmed,
    perPageUnconfirmed,
    perPageEmpty,
    currentPage,
    currentPageConfirmed,
    currentPageUnconfirmed,
    currentPageEmpty,
    startDate,
    endDate,
    totalInvoices,
    totalInvoicesConfirmed,
    totalInvoicesUnconfirmed,
    totalInvoicesEmpty,
    dataMeta,
    perPageOptions,
    searchQuery,
    searchQueryConfirmed,
    searchQueryUnconfirmed,
    searchQueryEmpty,
    sortBy,
    isSortDirDesc,
    refInvoicesListTableConfirmed,
    refInvoicesListTableUnconfirmed,
    refInvoicesListTableEmpty,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchDataAll,
    refetchDataAllInvoice,
    refetchDataConfirmedInvoice,
    refetchDataUnconfirmedInvoice,
    refetchDataEmptyInvoice,
    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
